import * as React from 'react';
import { Link, graphql, withPrefix } from 'gatsby';

import './getting-started.scss';

import Navbar from '../components/navbar';
import Footer from '../components/footer';
import { A } from '../components/a';
import EmailPopup from '../components/email-popup';
import Seo from '../components/seo'

const BlogIndex = () => {

  const activeId = useActiveId(['download', 'text-editor', 'get-template', 'whole-thing-together', 'tutorial-options']);

  function useActiveId(itemIds: string[]) {
    let [activeId, setActiveId] = React.useState(`download`);
    React.useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setActiveId(entry.target.id);
            }
          });
        },
        { rootMargin: `0% 0% -80% 0%` }
      );
      itemIds.forEach((id: string) => {
        const elem = document.getElementById(id);
        if (elem!=null) observer.observe(elem);
      });
      return () => {
        itemIds.forEach((id) => {
          const elem = document.getElementById(id);
          if (elem!=null) observer.unobserve(elem);
        });
      };
    }, [itemIds]);
    return activeId;
  }

  const videoStyle = {
    borderWidth : '0px',
    width: '560px',
    height: '315px',
    alignSelf: 'center'
  }

  return (
    <div id="main_content">
      <Seo title='Getting Started'/>
      <Navbar dark={true} />
      <EmailPopup/>

      <section className="page-banner">
        <div className="container">
          <div className="page-title-wrapper">
            <h1 className="page-title">Getting Started</h1>

            <ul className="bradcurmed">
              <li>
                <Link to="/">
                    Home
                </Link>              
              </li>
              <li>Getting Started</li>
            </ul>
          </div>
        </div>

        <svg
          className="circle"
          data-parallax='{"x" : -200}'
          xmlns="http://www.w3.org/2000/svg"
          width="950px"
          height="950px"
        >
          <path
            fillRule="evenodd"
            stroke="rgb(250, 112, 112)"
            strokeWidth="100px"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            opacity="0.051"
            fill="none"
            d="M450.000,50.000 C670.914,50.000 850.000,229.086 850.000,450.000 C850.000,670.914 670.914,850.000 450.000,850.000 C229.086,850.000 50.000,670.914 50.000,450.000 C50.000,229.086 229.086,50.000 450.000,50.000 Z"
          />
        </svg>

        <ul className="animate-ball">
          <li className="ball"></li>
          <li className="ball"></li>
          <li className="ball"></li>
          <li className="ball"></li>
          <li className="ball"></li>
        </ul>
      </section>
      <div className="blog-post-archive">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <p>
                On this page, we've collected the first steps of your journey to become a Skillgo
                developer. If you follow these steps, you will create your first Skillgo
                e-learning course scorm package.
              </p>
              <section className="getting-started-section">
                <div className="dummy-section" id='download'></div>
                <h2 id="section-1-title">1. Download Skillgo</h2>
                <p>First you need to download and install the Skillgo editor. Choose one of the following according to your platform:</p>
                <br></br>
                <div className="download-buttons text-center">
                  <a
                    href={withPrefix('/download/skillgo.exe')}
                    download
                    className="pix-btn btn-outline download-btn"
                  >
                    <i className="fab fa-windows" /> Download for Windows
                  </a>
                  <a href={withPrefix('/download/skillgo.dmg')} download className="pix-btn btn-outline download-btn">
                    <i className="fab fa-apple" /> Download for Mac
                  </a>
                  <a href="https://download.skillgo.io/files/skillgo/e84da83830e63d721442256f48f09267/latest/linux/x64/skillgo.deb" download className="pix-btn btn-outline download-btn-disabled">
                    <i className="fab fa-linux fa-ubuntu" /> Download for Linux - Coming soon
                  </a>
                  {/* <a href="https://update.skillgo.io/files/skillgo/e84da83830e63d721442256f48f09267/latest/linux/x64/skillgo.deb" download className="pix-btn btn-outline download-btn">
                      <i className="fab fa-linux fa-ubuntu" /> Download for Linux (DEB)
                      </a>
                      <a href="https://update.skillgo.io/files/skillgo/e84da83830e63d721442256f48f09267/latest/linux/x64/skillgo.rpm" download className="pix-btn btn-outline download-btn">
                      <i className="fab fa-redhat" /> Download for Linux (RPM)
                      </a> */}
                </div>
                <div className="download-buttons text-center" style={{ fontSize: '14px', marginBottom: '10px' }}>current version: {process.env.GATSBY_APP_VERSION}</div>

                <br></br>
                <iframe className="yt-video-embed"
                  src="https://www.youtube.com/embed/4d2dNa8piBg?si=6zyhK6SZBwuz-rf6"                    
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                  allowFullScreen>
                </iframe>

              </section>
              <section className="getting-started-section">
              <div className="dummy-section" id='text-editor'></div>
                <h2 id="section-2-title">2. Get your text editor</h2>
                <p>
                There are various text editors available for editing content. 
                However, we recommend using AsciidocFX, an application for editing <span><code>asciidoc</code></span>, 
                which is the foundational format for Skillgo. AsciidocFX is specifically 
                designed for this purpose and offers an array of quick menus and buttons that 
                facilitate easy formatting of <span><code>asciidoc</code></span> content. One downside to note is that 
                AsciidocFX may experience freezing issues when handling videos.


                <br></br>
                Available from here: <a href= "https://asciidocfx.com/" target="_blank">Download AsciidocFX</a>
                </p>

                <br></br>
                <iframe className="yt-video-embed"
                  src="https://www.youtube.com/embed/3SNpolZZitg?si=IgE-IuevldDMhcze" 
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                  allowFullScreen>
                </iframe>                 
              </section>


              <section className="getting-started-section">
                <div className="dummy-section" id='get-template'></div>
                <h2 id="section-3-title">3. Making My First Skillgo course material</h2>
                <p>
                Launch AsciidocFX and start building the structure of your first Skillgo 
                course as guided by the video below: 
                </p>

                <br></br>
                <iframe className="yt-video-embed"
                  src="https://www.youtube.com/embed/Y2Wt6WjJxr0?si=Zxw--suQwlvpaOdR"  
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                  allowFullScreen>
                </iframe>
 
                <br></br>
                <p>Alternatively, you can create your draft course with Skillgo easily:
                </p>

                <br></br>
                <iframe className="yt-video-embed"
                  src="https://www.youtube.com/embed/f0jdnXAl9tI?si=ixvF14yFeGqiVYzv"  
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                  allowFullScreen>
                </iframe>
                            
              </section>        
              
              <section className="getting-started-section">
                <div className="dummy-section" id='whole-thing-together'></div>
                <h2 id="section-3-title">4. Opening My First Skillgo course material</h2>
                <p>
                Upon completing the previous steps, you can launch Skillgo and open "My First Skillgo course material" and convert it to e-learning format.
                </p>                  
                {/* <p>For guidance on this process, please refer to the instructional 
                    video provided below:
                </p>

                <br></br>
                <iframe className="yt-video-embed"
                  src="https://www.youtube.com/embed/f0jdnXAl9tI?si=ixvF14yFeGqiVYzv"  
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                  allowFullScreen>
                </iframe> */}
                
              </section>
              
              <section className="getting-started-section">
                <div className="dummy-section" id='tutorial-options'></div>
                <h2 id="section-3-title">5. Tutorial options</h2>
                <p>
                  Here you can find out what features can be accessed and used with Skillgo:
                  <br></br>
                  <Link to="/tutorial">
                    Skillgo Tutorial
                  </Link>  
                </p>
                <p>
                  In our <a href= {withPrefix('tutorial/en/dashboard')} target="_blank"> complete learning material </a> 
                  you'll find everything you need to create your first Skillgo e-learning course!
                </p>
              </section>

           

              {/* <section className="getting-started-section">
                <div className="dummy-section" id='learning-material'></div>
                <h2 id="section-4-title">Learning material</h2>


              </section> */}

              {/* <section className="getting-started-section">
                <div className="dummy-section" id='final-steps'></div>
                <h2 id="section-5-title">Final Steps</h2>
                <p>
                  In this section, you can read about the final steps in creating an e-learning
                  course.
                </p>
              </section> */}
            </div>
            <div className="col-lg-4">
              <div className="sidebar sidebar-tableofcontents">
                <div id="categories" className="widget widget_tableofcontents">
                  <h2 className="widget-title">Table of Contents</h2>
                  <ul>
                    <li>
                      <a href={withPrefix('getting-started/#download')}
                      className = {activeId==="download" ? "toc-highlight" : ""}>1. Download Skillgo</a>
                    </li>
                    <li>
                      <a href={withPrefix('getting-started/#text-editor')}
                      className = {activeId==="text-editor" ? "toc-highlight" : ""}>2. Get your text editor</a>
                    </li>
                    <li>
                      <a href={withPrefix('getting-started/#get-template')}
                      className = {activeId==="get-template" ? "toc-highlight" : ""}>3. Making My First Skillgo course material</a>
                    </li>
                    <li>
                      <a href={withPrefix('getting-started/#whole-thing-together')}
                      className = {activeId==="whole-thing-together" ? "toc-highlight" : ""}>4. Opening My First Skillgo course material</a>
                    </li>
                    <li>
                      <a href={withPrefix('getting-started/#tutorial-options')}
                      className = {activeId==="tutorial-options" ? "toc-highlight" : ""}>5. Tutorial options</a>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BlogIndex;
